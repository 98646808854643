/* eslint-disable @typescript-eslint/no-unused-vars */
import { TopBar, Frame, AppProvider, Navigation } from "@shopify/polaris";
import { LogOutMinor, ProductsMajor, HomeMinor } from "@shopify/polaris-icons";
import enTranslations from "@shopify/polaris/locales/en.json";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./home.css"; // Ensure this is the correct path
import Dashboard from "./Home/Dashboard";
import Vendors from "./Home/Vendors";
import SheetView from "./Home/SheetView";
import UserDashboard from "./Home/UserDashboard";
import Profile from "./Home/Profile";
import LogoImage from "./../assets/quench_it_cropped.jpg";

const Main = () => {
  const nav = useNavigate();
  const [username, setUsername] = useState(() => {
    const user_obj: any = localStorage.getItem("userObj");
    let initialValue = "";
    try {
      initialValue = JSON.parse(user_obj).username;
    } catch (error) {
      initialValue = "";
      window.location.href = "/login";
    }
    return initialValue || "";
  });

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [locationParam] = useState(() => {
    const location_temp = typeof window !== "undefined" ? window.location.href : "";
    let init_value = location_temp.split("/").pop() || "";
    let user_obj: any = localStorage.getItem("userObj");
    user_obj = JSON.parse(user_obj);
    try {
      if (user_obj.data.device_id === init_value) {
        init_value = "plants";
      }
    } catch (error) {}
    return init_value || "";
  });

  useEffect(() => {
    const user_obj: any = localStorage.getItem("userObj");
    try {
      if (user_obj && JSON.parse(user_obj).username) {
        setUsername(JSON.parse(user_obj).username);
      }
    } catch (error) {
      nav("/login");
    }
  }, [nav]);

  async function logout() {
    localStorage.removeItem("userObj");
    nav("/login");
  }

  async function toggleIsUserMenuOpen() {
    setIsUserMenuOpen(prev => !prev);
  }

  const logo = {
    width: 124,
    topBarSource: LogoImage,
    url: "/",
    accessibilityLabel:
      window.location.hostname.indexOf("quenchit") > -1
        ? "quenchit"
        : "RR Devices",
  };

  function menuClick() {
    console.log("Menu clicked");
  }

  const location = typeof window !== "undefined" ? window.location.href : "";

  const [menuItems, setMenuItems] = useState([
    {
      label: "Dashboard",
      url: "/",
      selected: location.indexOf("/") > -1 && location.indexOf("/vendors") === -1 && location.indexOf("/sheet-view") === -1,
      onClick: menuClick,
      icon: HomeMinor,
    },
    {
      label: "Vendors",
      url: "/vendors",
      icon: ProductsMajor,
      selected: location.indexOf("/vendors") > -1,
      onClick: menuClick,
    },
    {
      label: "Sheet View",
      url: "/sheet-view",
      icon: ProductsMajor,
      selected: location.indexOf("/sheet-view") > -1,
      onClick: menuClick,
    },
  ]);

  const [isUserDashboard, setIsUserDashboard] = useState(() => {
    let init_val;
    console.log(window.location.hostname);
    if (window.location.hostname.indexOf("quenchit") > -1) {
      init_val = true;
    } else {
      init_val = false;
    }
    let user_obj: any = {},
      plant_id = "";
    if (window.localStorage.getItem("userObj")) {
      user_obj = JSON.parse(window.localStorage.getItem("userObj")!);
    }
    if (user_obj.data && user_obj.data.device_id) {
      plant_id = user_obj.data.device_id;
    }
    if (init_val) {
      setMenuItems([
        {
          label: "Dashboard",
          url: "/plants/" + plant_id,
          selected: location.indexOf("/plants") > -1 && location.indexOf("/vendors") === -1 && location.indexOf("/sheet-view") === -1,
          onClick: menuClick,
          icon: HomeMinor,
        },
        {
          label: "Profile",
          url: "/profile",
          icon: ProductsMajor,
          selected: location.indexOf("/profile") > -1,
          onClick: menuClick,
        },
      ]);
    }
    return init_val;
  });

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[{ items: [{ content: "Logout", icon: LogOutMinor, onAction: logout }] }]}
      name={username}
      detail={username}
      initials={username.substring(0, 1).toUpperCase()}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={() => {}}
    />
  );

  return (
    <AppProvider i18n={enTranslations}>
      <div className="shoppyn-admin-bg">
        <Frame topBar={topBarMarkup} logo={logo}>
          <div className="sidebar-width">
            <Navigation location="">
              <Navigation.Section items={menuItems} />
            </Navigation>
          </div>

          <div className="main-content">
            {locationParam === "" && <Dashboard />}
            {locationParam.indexOf("plants") > -1 && <UserDashboard />}
            {locationParam === "profile" && <Profile />}
            {locationParam === "vendors" && <Vendors />}
            {locationParam === "sheet-view" && <SheetView />}
          </div>
        </Frame>
      </div>
    </AppProvider>
  );
};

export default Main;
