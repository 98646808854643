import { useState } from "react";
import "../Login/login.css";
import "react-datepicker/dist/react-datepicker.css";
import "../../css/App.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, ToastOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ENDPOINT } from "../../common/config";
import LogoImageFull from "./../../assets/QuenchIT.svg";

const Login = () => {
  const toastr_options: ToastOptions = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const nav = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  const [isUserDashboard] = useState(() => {
    let init_val;
    if (window.location.hostname.indexOf("quenchit") > -1) {
      init_val = true;
    } else {
      init_val = false;
    }
    return init_val;
  });

  async function submitLogin() {
    setLoginLoading(true);
    var data,
      // hash = Buffer.from(email + `:` + password).toString("base64");
      hash = btoa(email + ':' + password);
    // var emailRegex =
    //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // if (email.match(emailRegex)) {
    //   data = JSON.stringify({
    //     hash
    //   });
    // } else {
    //   data = JSON.stringify({
    //     username: email,
    //     password,
    //   });
    // }
    // if (window.location.hostname.indexOf("quenchit") > -1) {
    //   data = JSON.stringify({
    //     username: email,
    //     password,
    //   });
    // }
    data = JSON.stringify({
      hash,
    });

    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4 && this.status === 200) {
        console.log(this.responseText);
        try {
          var json = JSON.parse(this.responseText);
          json.username = email;
          localStorage.setItem("userObj", JSON.stringify(json));
          setLoginLoading(false);
          if (window.location.hostname.indexOf("quenchit") > -1) {
            let device_id = json.data.device_id;
            nav("/plants/" + device_id);
          } else {
            nav("/");
          }
        } catch (error) {}
      } else if (this.readyState === 4) {
        console.log(this.responseText);
        setLoginLoading(false);
        var json_o = JSON.parse(this.responseText);
        if (json_o && json_o.message && json_o.message.length > 0) {
          toast.error(json_o.message, toastr_options);
        } else {
          toast.error("Please try again later", toastr_options);
        }
      }
    });

    xhr.open(
      "POST",
      ENDPOINT +
        (window.location.hostname.indexOf("quenchit") > -1
          ? "/v1/users/login"
          : "/v1/login")
    );
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(data);
  }

  return (
    <div className="login-bg">
      <div className="login-logo-parent">
        <div className="logo-header-parent text-center">
          <div className="pull-left full-width text-center display-ruby">
            {isUserDashboard && (
              <img
                width={250}
                height={100}
                alt="quenchit"
                src={LogoImageFull}
              />
            )}
          </div>
          <h2 className="w-full inline-block mt-2 mb-2 text-4xl font-bold">
            {isUserDashboard ? "" : "RR Devices"}
          </h2>
          <h4 className="logo-header text-2xl">
            {isUserDashboard ? "" : "SVM Admin Panel"}
          </h4>
        </div>
      </div>
      <div className="login-body-parent">
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={isUserDashboard ? "Username" : "Username or email"}
          className="login-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="login-input"
        />

        <div className="remember-forgot-section">
          <div className="remember-me-parent">
            <input type="checkbox" className="remember-me-checkbox" />
            <label className="remember-me-text">Remember Me</label>
          </div>
        </div>

        <div className="login-buttons-parent">
          <button
            type="submit"
            onClick={submitLogin}
            className="login-button"
            disabled={loginLoading}
          >
            {loginLoading ? (
              <svg
                className="w-5 h-5 mr-3 -ml-1 text-white animate-spin inline-block"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : null}
            Log In
          </button>
        </div>

        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        <ToastContainer />
      </div>
    </div>
  );
};

export default Login;
